<template>
  <div v-loading="loading" class="custom-dashboard-scheduler">
    <div class="d-flex p-2" style="border-bottom: 1px solid #ddd">
      <div class="left-card" style="min-width: 10vw">
        <div>
          <i class="fa fa-calendar today-icon"></i>
          <b>{{ component.component_name }}</b>
        </div>
      </div>
      <div class="d-flex right-card top-content">
        <div>
          <el-button type="primary" size="mini">Save</el-button>
          <el-dropdown trigger="click" v-if="!hide_options">
            <el-button
              icon="el-icon-more"
              style="transform: rotate(90deg); height: 40px"
              type="text"
            ></el-button>
            <el-dropdown-menu slot="dropdown">
              <a @click="deleteComponent">
                <el-dropdown-item>Delete</el-dropdown-item>
              </a>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="d-flex m-2">
      <div class="left-card" style="min-width: 10vw">
        <div>
          <el-date-picker
            v-model="currentWeek"
            type="week"
            format="yyyy MMM"
            placeholder="Pick a week"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="d-flex right-card top-content">
        <div>
          <el-button-group>
            <el-button size="mini" type="primary">Current week</el-button>
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-arrow-left"
              @click="changeWeek(false)"
              >Last week</el-button
            >
            <el-button size="mini" type="primary" @click="changeWeek(true)"
              >Next week <i class="el-icon-arrow-right"></i
            ></el-button>
          </el-button-group>
        </div>
      </div>
    </div>
    <div>
      <div class="calendar">
        <div class="weekView">
          <el-scrollbar>
            <table>
              <thead>
                <tr>
                  <th>
                    <el-select
                      size="mini"
                      placeholder="Please select"
                      v-model="viewBy"
                    >
                      <el-option
                        v-for="view in allViews"
                        :key="view.entity_id"
                        :label="view.name"
                        :value="view.entity_id"
                      ></el-option>
                    </el-select>
                  </th>
                  <th
                    class="p-1"
                    v-for="day in weekDays"
                    :key="`${day.weekday} ${day.month}/${day.day}`"
                  >
                    <span class="table-header">{{
                      `${day.weekday} ${day.month}/${day.day}`
                    }}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(row, i) in allEntitiesData[viewBy]"
                  :key="'resource_' + row._id"
                >
                  <td>
                    {{ getLabel(allEntities[viewBy], row, i) }}
                  </td>
                  <td
                    class="table-cell"
                    v-for="day in weekDays"
                    :key="`${day.weekday} ${day.month}/${day.day}_${row._id}`"
                  >
                    <div class="hidden-button">
                      <el-button
                        size="mini"
                        icon="el-icon-plus"
                        plain
                        style="border: 1px solid #409eff; color: #409eff"
                        @click="openDataModal(row, day.day)"
                      ></el-button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </el-scrollbar>
        </div>
      </div>
      <el-drawer
        title="I am the title"
        :visible.sync="addingNewData"
        :with-header="true"
        size="50%"
      >
        <div v-if="allEntities?.[this.component.scheduler_entity]">
          <fields-preview-template
            :templateData="
              allEntities[this.component.scheduler_entity].templates[0]
                .templateInfo
            "
            :has-next="false"
            :workflow-step-id="
              allEntities[this.component.scheduler_entity].templates[0]._id
            "
            :template-data-id="
              allEntities[this.component.scheduler_entity].templates[0]
                .templateDataId
            "
            :currentStep="
              allEntities[this.component.scheduler_entity].templates[0]
            "
            :entityData="null"
            :is-execute="true"
            :layout="'STANDARD'"
            :currentEntity="allEntities?.[this.component.scheduler_entity]"
            :isEdit="true"
            :currentMenu="null"
            :entityDataExists="false"
            :suggestionEntityForm="schedulingTemplateForm"
          >
          </fields-preview-template>
        </div>
      </el-drawer>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { postAPICall } from "@/helpers/httpHelper";

export default {
  data() {
    return {
      loading: false,
      loadingText: "Getting info..",
      shouldCenterMonth: false,
      currentDate: new Date(),
      currentYear: new Date().getFullYear(),
      currentView: "Month",
      daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      hours: [
        "12AM",
        "1AM",
        "2AM",
        "3AM",
        "4AM",
        "5AM",
        "6AM",
        "7AM",
        "8AM",
        "9AM",
        "10AM",
        "11AM",
        "12PM",
        "1PM",
        "2PM",
        "3PM",
        "4PM",
        "5PM",
        "6PM",
        "7PM",
        "8PM",
        "9PM",
        "10PM",
        "11PM",
      ],
      monthsNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      openNewEventModal: false,
      openNewEventLoading: false,
      dialogVisible: false,
      selectedEntity: "",
      currentEntity: null,
      entityAllFields: [],
      form: {},
      primaryFields: [],
      selectedData: null,
      showChildEntityDataDialog: false,
      calenderDataByMonth: {},
      calenderDateRangeByMonth: {},
      currentWeek: new Date(),
      allEntitiesData: {},
      allEntities: {},
      viewBy: "",
      allViews: [],
      addingNewData: false,
      schedulingTemplateForm: {},
    };
  },
  components: {
    FieldsPreviewTemplate: () =>
      import("@/components/templates/templateFieldsPreview.vue"),
  },
  props: [
    "isFromApplicationuser",
    "selectedEntities",
    "customDashboard",
    "parentDate",
    "parentView",
    "component",
    "index",
    "hide_options",
  ],

  computed: {
    ...mapGetters("entities", ["getEntityDataById"]),
    ...mapGetters("templatesData", [
      "getNewEntityData",
      "getPrimaryEntityDataStatus",
      "getTemplatesData",
      "getTemplatesPrimaryData",
      "getformtemplateinvoiceinfo",
      "getDuplicateDataCheck",
    ]),
    currentMonth() {
      return this.currentDate.toLocaleString("default", {
        month: "",
        year: "numeric",
      });
    },
    weeks() {
      const startDate = new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth(),
        1
      );
      const endDate = new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth() + 1,
        0
      );
      const weeks = [];
      let week = [];
      let dayCounter = 1;
      console.log(dayCounter);

      // Add empty cells for days before the start of the month
      for (let i = 0; i < startDate.getDay(); i++) {
        week.push("");
      }

      // Add days of the month
      for (let i = 1; i <= endDate.getDate(); i++) {
        week.push(i);
        if (week.length === 7) {
          weeks.push(week);
          week = [];
        }
      }

      // Add empty cells for days after the end of the month
      if (week.length > 0) {
        while (week.length < 7) {
          week.push("");
        }
        weeks.push(week);
      }

      return weeks;
    },
    weekDays() {
      const startDate = new Date(
        this.currentWeek.getFullYear(),
        this.currentWeek.getMonth(),
        this.currentWeek.getDate() - this.currentWeek.getDay()
      );
      const weekDays = [];

      for (let i = 0; i < 7; i++) {
        const date = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate() + i
        );
        weekDays.push({
          day: date.getDate(),
          month: date.getMonth() + 1,
          weekday: this.daysOfWeek[date.getDay()],
        });
      }

      return weekDays;
    },
    getAllViews() {
      let result = [];
      if (
        this.component?.resource_entity &&
        this.allEntities?.[this.component.resource_entity]
      ) {
        result.push(this.getView(this.component.resource_entity));
      }
      if (
        this.component?.job_entity &&
        this.allEntities?.[this.component.job_entity]
      ) {
        result.push(this.getView(this.component.job_entity));
      }
      return result;
    },
  },
  mounted() {
    this.getNeccessaryDetails();
  },
  methods: {
    deleteComponent() {
      this.$confirm("Are you sure to delete the scheduler?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.$emit("deleteComponent", this.index);
      });
    },
    openDataModal(row, day) {
      this.prepareSchedulingEntityTemplate(row, day);
      this.addingNewData = true;
    },
    getView(entity_id) {
      let { name, _id } = this.allEntities[entity_id];
      return {
        name: `View by ${name}`,
        entity_id: _id,
      };
    },
    prepareSchedulingEntityTemplate(row, day) {
      if (
        this.component?.scheduler_entity &&
        this.allEntities?.[this.component.scheduler_entity]?.templates?.[0]
          ?.templateInfo
      ) {
        let schedulingTemplate =
          this.allEntities?.[this.component.scheduler_entity].templates[0]
            .templateInfo;
        let fields = schedulingTemplate?.sections?.[0]?.fields || [];
        fields.forEach((fd) => {
          if (
            ["DATE", "DATE_TIME"].includes(fd?.inputType) &&
            this.component.scheduler_entity_date_field ==
              schedulingTemplate._id + "#" + fd.key
          ) {
            this.schedulingTemplateForm[fd.key] = new Date(
              this.currentWeek.getFullYear(),
              this.currentWeek.getMonth(),
              day
            );
          } else if (fd.input_type == "ENTITY") {
            if(this.viewBy == fd.entity_id){
              this.schedulingTemplateForm[fd.key] = row._id;
            }
          }
        });
        console.log("this.component.scheduler_entity)", day, row);
        console.log("schedulingTemplateForm", this.component.scheduler_entity_date_field, this.schedulingTemplateForm);
      }
    },
    getLabel(selectedEntity, item, i) {
      let name =
        item &&
        item.entity_prime_data &&
        Object.keys(item.entity_prime_data) &&
        item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
          ? item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
          : "data " + i;
      if (selectedEntity && selectedEntity.primaryFields) {
        let primaryField = selectedEntity.primaryFields[0];
        let key =
          primaryField && primaryField["key"] ? primaryField["key"] : null;
        if (key) {
          if (
            item.entity_prime_data &&
            item.entity_prime_data[key] &&
            item.entity_prime_data[key + "/name"]
          ) {
            name = item.entity_prime_data[key + "/name"]
              ? item.entity_prime_data[key + "/name"]
              : item.entity_prime_data[key];
          } else {
            name =
              item.entityData &&
              primaryField.template_id &&
              item.entityData[primaryField.template_id]
                ? item.entityData[primaryField.template_id][key + "/name"]
                  ? item.entityData[primaryField.template_id][key + "/name"]
                  : item.entityData[primaryField.template_id][key]
                  ? item.entityData[primaryField.template_id][key]
                  : "data" + i
                : "data" + i;
          }
        }
      }
      return name;
    },
    async getNeccessaryDetails() {
      let promises = [];
      if (this.component?.resource_entity) {
        promises.push(
          this.fetchEntityData(
            this.component.resource_entity,
            this.component.resource_entity_filter
          )
        );
        this.viewBy = this.component.resource_entity;
      }
      if (this.component?.job_entity) {
        promises.push(
          this.fetchEntityData(
            this.component.job_entity,
            this.component.job_entity_filter
          )
        );
      }
      if (this.component?.scheduler_entity) {
        promises.push(this.fetchEntityData(this.component.scheduler_entity));
      }
      await Promise.all(promises);
      this.allViews = this.getAllViews;
    },
    async fetchEntityData(entity_id, filter_id = "") {
      let params = {
        entity_id: entity_id,
        limit: 500,
        page: 1,
        filters: [],
        filter_id: filter_id,
      };
      let entityDataResponse = await postAPICall(
        "POST",
        "/entities-data/entity/data",
        params
      );
      this.allEntitiesData[entity_id] = entityDataResponse.data;
      this.allEntities[entity_id] = entityDataResponse.selectedEntity;
    },
    //goto today date
    SetTodayDate() {
      this.currentDate = new Date();
    },
    getCurrentDate() {
      if (this.currentView == "Month") {
        return (
          this.monthsNames[this.currentDate.getMonth()] +
          "-" +
          this.currentDate.getFullYear()
        );
      }
      return this.currentDate.toLocaleDateString();
    },
    changeWeek(add = false) {
      this.currentWeek = new Date(
        this.currentWeek.getFullYear(),
        this.currentWeek.getMonth(),
        add ? this.currentWeek.getDate() + 7 : this.currentWeek.getDate() - 7
      );
      if (this.currentYear != this.currentWeek.getFullYear()) {
        this.currentYear = this.currentWeek.getFullYear();
        this.$emit("newEntityData", {
          date: this.currentWeek,
          view: this.currentView,
        });
      }
    },
    changeView(view) {
      this.currentView = view;
    },
    isCurrentDay(day) {
      const today = new Date();
      return (
        day !== "" &&
        this.currentDate.getMonth() === today.getMonth() &&
        this.currentDate.getFullYear() === today.getFullYear() &&
        day === today.getDate()
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-dashboard-scheduler {
  border: 1px solid #eaeaea;
  border-radius: 5px;
  margin-bottom: 0px;
  margin-top: 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1) !important;
  background-color: #ffffff;
}
.left-card {
  flex: 1;
  width: 100%;
}
.right-card {
  flex: 4;
  width: 100%;
  justify-content: end;
  align-items: center;
}
</style>

<style scoped lang="scss">
.weekday-container {
}
.weekday-single {
  width: 100% !important;
  height: 14px;
  border-radius: 4px;
  font-size: 9px;
  color: white;
  margin-top: 2px;
}
.event-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  min-height: 6px;
}
.single-event {
  height: 6px;
  width: 6px;
  border-radius: 50%;
}
.calendar {
  border-top: 1px solid #ccc;
  /* padding: 10px; */
  width: 100%;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
}

.child-a {
  display: flex;
  align-items: center;
  gap: 10px;
}

.today-icon-container {
  display: inline-block;
  position: relative;
}

.prev-next-btn {
  font-size: 20px;
  color: #007bff;
  margin: 0 10px;
}

.current-date {
  font-size: 16px;
  color: #333;
}

.view-select {
  width: 100px;
  font-size: 14px;
  color: #333;
  margin-right: 20px;
}

.el-tooltip__popper {
  font-size: 12px; /* Tooltip font size */
}

/* Styles for the calendar table */
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  text-align: center;
  border: 1px solid #ccc;
}
td {
  height: 75px;
  position: relative;
  vertical-align: center;
}

td .cell-content {
  padding: 10px;
}

/* Style for the current day */
.current-day {
  background-color: lightblue;
}

.cell-content {
  position: relative;
  padding: 5px;
}

.event {
  background-color: #f0f0f0;
  border-radius: 3px;
  padding: 2px 5px;
  margin-top: 2px;
}

.event:first-child {
  margin-top: 5px;
}
.table-header {
  font-weight: bold !important;
}

.table-cell {
  position: relative;
}

.hidden-button {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.table-cell:hover .hidden-button {
  display: block;
}
</style>
